import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from 'app/hooks/reduxCreateSelectorHooks';
import {TrustLevel} from 'app/helpers/trustLevel.const';

const checkIsReportable = (reportableAt: string | null) => {
  if (!reportableAt) {
    return false;
  }
  const reportableAtDate = new Date(reportableAt).getTime();
  const now = new Date().getTime();
  return reportableAtDate <= now;
};

const checkIsAllowedUser = (trustLevel?: number | null): boolean => {
  return !!(trustLevel && trustLevel >= TrustLevel.UserWithTicker);
};

export const isSofttickerEligible = (match: any) => {
  return useMemo(() => {
    const hasTicker = !!match.flags?.includes('ticker');
    const isReportable = checkIsReportable(match.softtickerResultReportableAt);
    return isReportable && !hasTicker;
  }, [match]);
};

export default function useAllowSoftTicker(match: any) {
  if (!match) {
    return false;
  }
  const {claims}: any = useSelector(selectCurrentUser);

  const isAllowedUser = useMemo(() => {
    const trustLevel = claims?.tl;
    return checkIsAllowedUser(trustLevel);
  }, [claims]);

  return isSofttickerEligible(match) && isAllowedUser;
}

const checkIsReportableUntil = (reportableUntil: string | null) => {
  if (!reportableUntil) {
    return false;
  }
  const reportableUntilDate = new Date(reportableUntil).getTime();
  const now = new Date().getTime();
  return reportableUntilDate >= now;
};

export function useAllowSoftTickerEvents(match: any) {
  const {claims}: any = useSelector(selectCurrentUser);

  const isAllowedUser = useMemo(() => {
    const trustLevel = claims?.tl;
    return checkIsAllowedUser(trustLevel);
  }, [claims]);

  const isSoftTickerEligible = useMemo(() => {
    const hasSoftticker = !!match.flags?.includes('softticker');
    const isReportable = checkIsReportableUntil(match.softtickerEventsReportableUntil);
    return isReportable && hasSoftticker;
  }, [match]);

  return isAllowedUser && isSoftTickerEligible;
}

export function useIsNotSuperAdmin() {
  const {claims}: any = useSelector(selectCurrentUser);

  return useMemo(() => {
    const trustLevel = claims?.tl;
    return trustLevel && trustLevel < TrustLevel.SuperAdmin;
  }, [claims]);
}
